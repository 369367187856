* {
    -webkit-font-smoothing: antialiased;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
    font-family: Montserrat;
    background-color: #0d0d0d;
}

.App {
    text-align: center;
    background-color: #0d0d0d;
    min-height: 100vh;
    min-width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    user-select: none;
    transition: color 600ms;
}

.vugnaes-header {
    font-size: 45px;
    font-weight: 500;
    position: relative;
    /* cursor: pointer; */
    transition: animation 200ms;
}

.vugnaes-header:hover span {
    display: block;
    /* animation: wiggle 500ms 2 forwards; */
    pointer-events: all;
}

.vugnaes-header.hidden span::after {
    visibility: hidden;
}

.profilelink {
    text-decoration: none;
    color: inherit;
    position: fixed;
    bottom: 10px;
    left: 10px;
}

.profilelink:hover {
    font-weight: 500;
}

.vugnaes-publicando {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    font-size: 24px;
    line-height: 70px;
    background-color: #0d0d0de2;
    transition: all 200ms, color 0ms;
    opacity: 0;
}

.vugnaes-publicando.shown {
    opacity: 1;
}

.vugnaes-placeholder {
    opacity: 0;
}

.vugnaes-info-container {
    margin-top: 20px;
    outline: 2px solid;
    outline-color: inherit;
    width: 90%;
    max-width: 600px;
    font-size: 60px;
    border-radius: 20px;
    font-weight: 500;
    box-sizing: border-box;
    overflow: hidden;
    max-height: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: 0px;
    padding: 0px;
    transition: all 500ms, color 0ms;
}

.vugnaes-info-container.shown {
    max-height: 400px;
    padding: 16px;
    overflow: visible;
}

.vugnaes-total {
    display: flex;
    align-items: center;
    font-size: 14px;
    max-height: 0px;
    opacity: 0;
    margin: 0px;
    transition: all 400ms, color 0ms;
}

.vugnaes-total.shown {
    opacity: 1;
    margin: 18px 0px 8px 8px;
}

.vugnaes-info {
    display: inline-block;
    min-width: 18px;
    min-height: 18px;
    line-height: 18px;
    outline: 1px solid;
    outline-color: inherit;
    border-radius: 50%;
    margin-left: 10px;
    position: relative;
    cursor: pointer;
}

.vugnaes-info-bubble {
    position: absolute;
    outline: 1px solid;
    outline-color: inherit;
    width: 240px;
    padding: 12px;
    border-radius: 10px;
    left: 50%;
    transform: translateX(-80%) translateY(-20px);
    top: 100%;
    margin-top: 16px;
    font-size: 14px;
    text-align: justify;
    transition: all 200ms;
    opacity: 0;
    pointer-events: none;
    cursor: default;
    background-color: #0d0d0d;
}

.vugnaes-info:hover .vugnaes-info-bubble {
    transform: translateX(-80%) translateY(0px);
    opacity: 1;
    pointer-events: all;
}

.vugnaes-info-bubble::before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 80%;
    width: 100px;
    height: 25px;
    transform: translateX(-50%);
}

.vugnaes-info-bubble::after {
    position: absolute;
    content: "";
    width: 14px;
    height: 14px;
    background-color: #0d0d0d;
    border: 1px solid;
    border-color: inherit;
    border-left: none;
    border-bottom: none;
    top: -8px;
    left: 80%;
    transform: rotateZ(-45deg) translateX(-50%);
    transform-origin: center left;
}

.vugnaes-info-bubble a {
    color: inherit;
}

.vugnaes-info-bubble a:hover {
    font-weight: 500;
}

.color-select {
    display: flex;
    position: fixed;
    bottom: 10px;
    right: 10px;
    transition: all 200ms;
    outline: 1px solid;
    border-radius: 8px;
    align-items: center;
    padding: 6px 8px;
    font-weight: 500;
    z-index: 11;
}

.color-select div {
    filter: saturate(0);
    width: 18px;
    height: 18px;
    outline: 1px solid #000;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 8px;
    transition: all 200ms;
}

.color-select div:first-of-type {
    margin: 0px;
}

.color-select div.selected,
.color-select:hover div {
    filter: saturate(1);
}

.color-select div:hover {
    scale: 1.1;
}

.vugnaes-select-info {
    display: flex;
    margin-top: 20px;
    justify-content: center;
    margin-bottom: 80px;
    opacity: 0;
    transition: all 300ms, color 0ms;
}

.vugnaes-select-info.shown {
    opacity: 1;
}

.vugnaes-select-option {
    outline: 1px solid;
    width: 100px;
    border-radius: 6px;
    font-size: 14px;
    padding: 4px;
    box-sizing: border-box;
    margin-left: 14px;
    transition: all 200ms, background-color 600ms, color 0ms;
    cursor: pointer;
}

.vugnaes-select-option:not(.selected):hover {
    scale: 1.05;
    background-color: #fff;
}

.vugnaes-select-option:first-of-type {
    margin: 0px;
}

.vugnaes-select-option.selected {
    color: #000;
    font-weight: 700;
    cursor: default;
}

.vugnaes-top {
    font-size: 16px;
    overflow: hidden;
    max-height: 0px;
    opacity: 0;
    transition: all 200ms, color 0ms, opacity 400ms;
}

.vugnaes-info-container.shown .vugnaes-top.top {
    opacity: 1;
}

.vugnaes-top.top {
    max-height: 500px;
    transition: all 400ms 500ms, color 0ms, opacity 400ms;
}

.vugnaes-top-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid;
    margin-bottom: 4px;
}

.vugnaes-top-qtty {
    font-weight: 500;
    margin: 0px 2px -4px 0px;
    width: 50px;
    text-align: right;
}

.vugnaes-top-number {
    display: inline-block;
    width: 18px;
    margin-right: 6px;
}

.vugnaes-top-select {
    display: flex;
    margin: 4px 4px 20px;
    justify-content: center;
    transition: all 400ms, height 500ms, max-height 500ms;
}

.vugnaes-number-container {
    display: flex;
    flex-direction: column;
    max-height: 0px;
    opacity: 0;
    pointer-events: none;
    transition: all 400ms, color 0ms;
}

.vugnaes-info-container.shown .vugnaes-number-container.shown {
    opacity: 1;
}

.vugnaes-number-container.shown {
    max-height: 500px;
    pointer-events: all;
}

.vugnaes-top-list {
    max-height: 500px;
    transition: all 400ms;
}

.vugnaes-top-list.hidden {
    max-height: 0px;
}

@keyframes wiggle {
    0% {
        -webkit-transform: skewX(24deg);
    }
    10% {
        -webkit-transform: skewX(-8deg);
    }
    20% {
        -webkit-transform: skewX(55deg);
    }
    30% {
        -webkit-transform: skewX(-90deg);
    }
    40% {
        -webkit-transform: skewX(29deg);
    }
    50% {
        -webkit-transform: skewX(-90deg);
        text-shadow: 5px 5px rgba(255, 0, 0, 0.655),
            -5px -5px rgba(0, 0, 0, 0.671), -5px 5px rgba(0, 0, 255, 0.734);
    }
    60% {
        -webkit-transform: skewX(3deg);
    }
    70% {
        -webkit-transform: skewX(-2deg);
    }
    80% {
        -webkit-transform: skewX(1deg);
    }
    90% {
        -webkit-transform: skewX(10deg);
    }
    100% {
        -webkit-transform: skewX(0deg);
        text-shadow: none;
    }
}

.vugnaes-modal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: #000;
    z-index: 10;
    transition: all 2000ms;
    opacity: 0;
    pointer-events: none;
}

.vugnaes-modal.shown {
    opacity: 1;
    pointer-events: all;
}

.vugnaes-computer-btn {
}
